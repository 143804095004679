import React from "react";
import LatestArticlesItem from "./latestArticlesItem";
import { List } from "components/layout";

/* Latest Articles
============================================================================= */

const LatestArticles = ({ articles }) => {
	// Config

	// Render Articles
	const renderArticles = () => {
		return articles.map((article, i) => {
			return <LatestArticlesItem key={article.title} article={article} />;
		});
	};

	// Render
	return (
		<List
			title="Latest Articles"
			ariaLabel="Latest Articles"
			skipLink="#footer"
			grid="2xl:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-12"
			className="padding-bottom"
		>
			{renderArticles()}
		</List>
	);
};

/* Export
============================================================================= */

export default LatestArticles;
