import React from "react";
import { Link } from "gatsby";
import Image from "gatsby-image";
import { kebabCase, getGatsbyImage, getDate } from "helpers";

/* Latest Articles Item
============================================================================= */

const LatestArticlesItem = ({ article }) => {
	// Config

	// Render
	return (
		<Link
			aria-label="I Wonder What This Guy is Looking At: Perhaps it is a Painting, By Greta Balog"
			to={`/news/${kebabCase(article.title)}`}
			className="w-full group hover focus:scale-105 outline-none"
		>
			<div className="w-full shadow-xl mb-4 rounded-md">
				<Image
					fluid={getGatsbyImage(article.image)}
					alt={article.image.alt}
					className="oblong shadow-lg duration-200 ease-in-out rounded-md transition-shadow group-focus outline-none"
					placeholderStyle={{
						filter: "blur(50px)",
					}}
					imgStyle={{
						objectFit: "cover",
						objectPosition: "50% 50%",
					}}
				/>
			</div>
			<time
				datetime="2020-11-16"
				className="font-body uppercase text-xs tracking-wider text-gray-900"
			>
				{getDate(article._createdAt)}
			</time>
			<h3 className="font-body text-lg font-semibold pt-1 leading-tight">
				{article.title} <br />{" "}
				<span className="font-body text-sm tracking-wide text-gold font-bold">
					by {article.author.name}
				</span>
			</h3>
		</Link>
	);
};

/* Export
============================================================================= */

export default LatestArticlesItem;
