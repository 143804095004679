import React from "react";
import { EpisodeItem } from "components";
import { List } from "components/layout";

/* Latest Episodes
============================================================================= */

const LatestEpisodes = ({ episodes }) => {
	// Config

	// Render Episodes
	const renderEpisodes = () => {
		return episodes.map((episode, i) => {
			return (
				<EpisodeItem
					key={episode.name}
					episode={episode}
					noTranscript={episode._rawTranscript === null}
				/>
			);
		});
	};

	// Render
	return (
		<List
			title="Latest Episodes"
			ariaLabel="Latest Episodes"
			skipLink="#featured-article"
			grid="grid-cols-1 gap-8"
			className="pb-16"
		>
			{renderEpisodes()}
		</List>
	);
};

/* Export
============================================================================= */

export default LatestEpisodes;
