import React from "react";
import { graphql, useStaticQuery, Link } from "gatsby";
import Image from "gatsby-image";
import { kebabCase, getGatsbyImage } from "helpers";

/* Hero
============================================================================= */

const Hero = ({ hero }) => {
	// Config

	// Render
	return (
		<section
			role="region"
			aria-label="Featured Article"
			className="w-full relative h-96"
		>
			<div className="offset-gradient h-full horizontal-padding relative z-50 flex flex-col items-start justify-end pb-8">
				<h4 className="font-body text-white uppercase tracking-wider text-sm font-bold pb-2">
					Featured Article
				</h4>
				<Link
					to={`/news/${kebabCase(hero.title)}`}
					aria-label={hero.title}
					className="md:w-4/6 w-full"
				>
					<h2 className="font-display text-4xl text-white leading-tightest">
						{hero.title}
					</h2>
				</Link>
			</div>
			<Image
				fluid={getGatsbyImage(hero.image)}
				alt={hero.image.alt}
				style={{
					position: "absolute",
					top: 0,
					bottom: 0,
					width: "100%",
					height: "100%",
				}}
				imgStyle={{
					objectFit: "cover",
					objectPosition: "50% 50%",
				}}
			/>
		</section>
	);
};

/* Export
============================================================================= */

export default Hero;
