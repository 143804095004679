import React from "react";
import { PodcastItem } from "components";
import { List } from "components/layout";
import _ from "lodash";

/* Featured Podcasts
============================================================================= */

const FeaturedPodcasts = ({ podcasts, outNow, comingSoon }) => {
	// Config

	// Render Podcasts Out Now
	const renderOutNow = () => {
		return outNow.map((podcast) => {
			return <PodcastItem podcast={podcast} key={podcast.name} />;
		});
	};

	// Render Podcasts Coming Soon
	const renderComingSoon = () => {
		return comingSoon.map((podcast) => {
			return <PodcastItem podcast={podcast} key={podcast.name} />;
		});
	};

	// Render
	return (
		<>
			<List
				ariaLabel="Out Now"
				title="Out Now"
				grid="2xl:grid-cols-3 xl:grid-cols-3 sm:grid-cols-2 grid-cols-1 gap-10"
			>
				{renderOutNow()}
			</List>
		</>
	);
};

/* Export
============================================================================= */

export default FeaturedPodcasts;
