import React from "react";
import { Link, graphql } from "gatsby";
import { Helmet } from "react-helmet";
import { SEO } from "components";
import {
	Hero,
	FeaturedPodcasts,
	LatestEpisodes,
	FeaturedArticle,
	LatestArticles,
	NewsletterSubscription,
	About,
	Donate,
} from "components/home";

/* Home Page
============================================================================= */

const HomePage = ({ data }) => {
	// Config

	// Render
	return (
		<>
			<SEO title="Home" />
			<Helmet>
				<meta
					name="facebook-domain-verification"
					content="rqy4qavd0j214otxo8r90qppkjn9fx"
				/>
			</Helmet>
			<FeaturedPodcasts
				podcasts={data.allSanityPodcast.nodes}
				outNow={data.sanitySiteConfig.podcasts_out_now}
				comingSoon={data.sanitySiteConfig.podcasts_coming_soon}
			/>
			<LatestEpisodes episodes={data.allSanityEpisode.nodes} />
			<Hero hero={data.sanitySiteConfig.featured_news_article} />
			<LatestArticles articles={data.allSanityNewsArticle.nodes} />
		</>
	);
};

/* Page Query
============================================================================= */
// NOTE: Change sort field to _createdAt after Rising 2021 is done
export const pageQuery = graphql`
	query HomePageQuery {
		allSanityEpisode(sort: { order: DESC, fields: date }, limit: 5) {
			nodes {
				_createdAt
				_rawTranscript(resolveReferences: { maxDepth: 10 })
				podcast {
					name
					podcast_id
				}
				name
				_rawDescription(resolveReferences: { maxDepth: 10 })
				date
				episode_id
				episode_number
			}
		}
		allSanityNewsArticle(
			limit: 6
			sort: { order: DESC, fields: _createdAt }
		) {
			nodes {
				author {
					name
					facebook
					twitter
				}
				image {
					alt
					image {
						asset {
							localFile {
								childImageSharp {
									fluid(maxWidth: 750) {
										src
										srcSet
									}
								}
							}
						}
					}
				}
				title
				_createdAt
			}
		}
		sanitySiteConfig {
			featured_news_article {
				author {
					name
					_createdAt
				}
				blurb
				title
				image {
					image {
						asset {
							localFile {
								childImageSharp {
									fluid(maxWidth: 1200) {
										base64
										srcWebp
										srcSetWebp
										originalImg
										originalName
									}
								}
							}
						}
					}
					alt
				}
			}
			podcasts_coming_soon {
				description {
					_rawChildren(resolveReferences: { maxDepth: 10 })
				}
				special_label
				tagline
				hosts {
					description
					name
					role
					twitter
					facebook
					image {
						image {
							asset {
								localFile {
									childImageSharp {
										fluid(maxWidth: 750) {
											src
											srcSet
											base64
											srcWebp
											srcSetWebp
											originalImg
											originalName
										}
									}
								}
							}
						}
						alt
					}
				}
				name
				image {
					image {
						asset {
							localFile {
								childImageSharp {
									fluid(maxWidth: 750) {
										src
										srcSet
										base64
										srcWebp
										srcSetWebp
										originalImg
										originalName
									}
								}
							}
						}
					}
					alt
				}
			}
			podcasts_out_now {
				description {
					_rawChildren(resolveReferences: { maxDepth: 10 })
				}
				special_label
				tagline
				hosts {
					description
					name
					role
					twitter
					facebook
					image {
						image {
							asset {
								localFile {
									childImageSharp {
										fluid(maxWidth: 750) {
											src
											srcSet
											base64
											srcWebp
											srcSetWebp
											originalImg
											originalName
										}
									}
								}
							}
						}
						alt
					}
				}
				name
				image {
					image {
						asset {
							localFile {
								childImageSharp {
									fluid(maxWidth: 750) {
										src
										srcSet
										base64
										srcWebp
										srcSetWebp
										originalImg
										originalName
									}
								}
							}
						}
					}
					alt
				}
			}
		}
		allSanityPodcast {
			nodes {
				description {
					_rawChildren(resolveReferences: { maxDepth: 10 })
				}
				special_label
				tagline
				hosts {
					description
					name
					role
					twitter
					facebook
					image {
						image {
							asset {
								localFile {
									childImageSharp {
										fluid(maxWidth: 750) {
											src
											srcSet
											base64
											srcWebp
											srcSetWebp
											originalImg
											originalName
										}
									}
								}
							}
						}
						alt
					}
				}
				name
				image {
					image {
						asset {
							localFile {
								childImageSharp {
									fluid(maxWidth: 750) {
										src
										srcSet
										base64
										srcWebp
										srcSetWebp
										originalImg
										originalName
									}
								}
							}
						}
					}
					alt
				}
			}
		}
	}
`;

/* Export
============================================================================= */

export default HomePage;
